import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { CgWebsite } from 'react-icons/cg';
import { BsGithub } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai'; // Importing the X icon

function ProjectCards(props) {
  const [showModal, setShowModal] = useState(false);

  const handleCardClick = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Card className="project-card-view" onClick={handleCardClick}>
        <Card.Body>
          <Card.Title className="title_projects">{props.title}</Card.Title>
        </Card.Body>
        <Card.Img variant="top" src={props.imgPath} alt="card-img" />

      </Card>

      <Modal show={showModal} onHide={handleClose} size="lg">
        <Modal.Header >
          <Modal.Title>{props.title}</Modal.Title>
          <Button variant="none" className="btn-close" onClick={handleClose}>
            <AiOutlineClose /> {/* X icon for closing the modal */}
          </Button>
        </Modal.Header>
        <Modal.Body>
          <img src={props.imgPath2} alt="card-img" style={{ width: '100%' }} />
          <p>{props.techStack}</p>
          <p>{props.description}</p>
        </Modal.Body>
        <Modal.Footer>

          <Button variant="primary" href={props.ghLink} target="_blank">
            <BsGithub /> &nbsp;
            {props.isBlog ? "Blog" : "GitHub"}
          </Button>
          {!props.isBlog && props.demoLink && (
            <Button
              variant="primary"
              href={props.demoLink}
              target="_blank"
              style={{ marginLeft: "10px" }}
            >
              <CgWebsite /> &nbsp;
              {"Demo"}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ProjectCards;
