import React, { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar/';
import { Link } from 'react-router-dom';
import './index.scss';
import Headroom from 'react-headroom';

const Layout = () => {
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    setTooltipPosition({ top: clientY - 5, left: clientX - 100 });
  };

  // Add a scroll event listener to check if the user has scrolled
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`App ${isScrolled ? 'scrolled' : ''}`} onMouseMove={handleMouseMove}>
      <Sidebar />
      <div className="page">
        <span
          className="link-name-tag"
          onMouseEnter={() => setTooltipVisible(true)}
          onMouseLeave={() => setTooltipVisible(false)}
        >
          Chris Hatoum
          {isTooltipVisible && (
            <span className="tooltip" style={{ top: tooltipPosition.top, left: tooltipPosition.left }}>
              <span className="tooltip-box">Cool dude!</span>
            </span>
          )}
        </span>
        <Outlet />
        <div className="tags bottom-tags">
          <div className="bottom-tag-html"></div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
