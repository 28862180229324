import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About2.0';

import Contact from './components/Contact';
import Layout from './components/Layout';
import Portfolio from './components/Portfolio2.0';
import Dashboard from './components/Dashboard';
import VantaGlobe from './components/VantaGlobe'; // Import the VantaGlobe component
import './App.scss';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            index
            element={(
              <>
                
                <Home />
                <VantaGlobe />
              </>
            )}
          />

          <Route path="about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
