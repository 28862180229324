import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import climbsafe from "../../assets/Projects/climbsafe-both.png";
import sortmain from "../../assets/Projects/Sorting-main.png";
import sortmodal from "../../assets/Projects/Sorting-modal.png";
import GSSS from "../../assets/Projects/GSSS-both.png";
import cortilemain from "../../assets/Projects/IlCortile-main.png";
import cortilemodal from "../../assets/Projects/IlCortile-modal.png";
import personalmain from "../../assets/Projects/personal-main.png";
import personalmodal from "../../assets/Projects/personal-modal.png";
import CNN from "../../assets/Projects/CNN-both.png";
import robotmain from "../../assets/Projects/robot-main.png";
import robotmodal from "../../assets/Projects/robot-modal.png";
import Loader from 'react-loaders'
import { Link } from 'react-router-dom'
import { useEffect } from "react";

import "./index.scss";

function Projects() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div> <Container fluid className="project-section">
    
      <Loader className="loader-container" type="pacman" />
    
    <Particle />
    <Container>
      <h1 className="project-heading">
      Creations Corner
      </h1>
      
      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={sortmain}
            imgPath2={sortmodal}
            isBlog={false}
            title="Sorting Algorithms"
            techStack= "TeckStack: HTML, CSS, JS, BootStrap, React, Netlify"
            description="Description : Implemented a visualization of Insertion sort, Selection Sort and Bubble sort.
            This project also includes time/ space complexities of these sorting algorithms as well as a general description and 
            their overal coding structure. This project was used as a tool to practice on my react skills as well as a good practice for 
            my software courses."
            ghLink="https://github.com/Chrishatoum/Sorting-Algorithms"
            demoLink="https://chrishatoum-sortingalgos.netlify.app/"
          />
        </Col>

        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={GSSS}
            imgPath2={GSSS}
            isBlog={false}
            title="Grocery Store Software System"
            techStack= "TeckStack: Java, Gradle, Spring/Spring Boot, Postgres, Vue.js, UML, Android SDK "
            description="Description : This project involves developing a software system for a local grocery store. 
            The system includes a website and app for online ordering and account management. It focuses on features 
            like online payment and special services for local residents. The development process encompasses
             requirement analysis, backend and frontend development, and system validation. Technologies used 
             include UML, Postgres, Hibernate, Java Spring/Spring Boot, Vue.js, and Android SDK. The project 
             is executed in four phases, covering database, backend, web, and mobile app development."
            ghLink="https://github.com/Chrishatoum/project-group-group-12"
            demoLink="https://www.youtube.com/watch?v=CsE2xNE7Ep4"
          />
        </Col>

        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={climbsafe}
            imgPath2={climbsafe}
            isBlog={false}
            title="ClimbSafe"
            techStack= "TeckStack: Java, Umple, JavaFX, Gherkin, Cucumber"
            description="Description :The ClimbSafe project is designed for a fictional Himalayan country,
             Neptan, to manage mountain climbing activities. It's a software application 
             that allows users to register as climbers, book mountain guides, rent equipment,
              and arrange hotel stays. An administrator controls key functions like 
              setting the climbing season and managing equipment and hotel listings. The 
              application calculates costs for services and assigns guides and hotels to members.
               Developed in Java, it involves iterations focusing on domain modeling, UI design, and controller implementation."
            ghLink="https://github.com/Chrishatoum/ClimbSafe"
             
          />
        </Col>
        
        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={cortilemain}
            imgPath2={cortilemodal}
            isBlog={false}
            title="Cafe Il Cortile website"
            techStack= "TeckStack: Python, BootStrap, React, UML, Postgres"
            description="Description :This is an ongoing and progressing project that I'm implementing for Cafe Il Cortile.
             Many features such as on-site reservation, and an owners webpage to implement new prices / new items / etc... is still ongoing.
             Expected delivery date : April 2024  "
            demoLink="https://cafeilcortile.com/"
          />
        </Col>

        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={personalmain}
            imgPath2={personalmodal}
            isBlog={false}
            title="Personal Website"
            techStack= "TeckStack: HTML, CSS, JS, BootStrap, React, Netlify"
            description="Description : My personal portfolio involving an about section, a project section and a contact page. This project taught me 
            a lot of front-end skills, such as  the use of react components, hooks, connecting multiple webpages to each other, implementing emailJS... "
            ghLink="https://github.com/Chrishatoum/myportfolio"
            demoLink="./"
           
          />
        </Col>

        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={CNN}
            imgPath2={CNN}
            isBlog={false}
            title="Multilayer Perceptrons and CNN"
            techStack= "TeckStack: Python, PyTorch Framework"
            description="Description : This project delves into neural network-based image classification,
             starting with building a multilayer perceptron from the ground up, and advancing
              to convolutional neural networks and transfer learning techniques. Conducted with 
              Fashion MNIST and CIFAR-10 datasets, the study evaluates the effects of network architecture, 
              weight initialization, and other hyperparameters on model accuracy. The project combines theoretical
               understanding with practical experimentation, leading to a nuanced view of neural network 
               performance in image classification tasks."
            ghLink="https://github.com/Chrishatoum/NeuralNetworksCNN"
            
          />
        </Col>     
        <Col md={4} className="project-card">
          <ProjectCard
            imgPath={robotmain}
            imgPath2={robotmodal}
            isBlog={false}
            title="Cube Dropping Robot"
            techStack= "TeckStack: Python, BrickPi, LEGO Mindstorm"
            description="Description : The robot in this project is a sophisticated automated delivery system, 
            combining Python programming, BrickPi, and LEGO Mindstorms components. It autonomously transports 
            colored cubes to designated zones on a map, guided by EV3 Color Sensors for color matching and navigation.
             Programmed in Python, the robot efficiently delivers cubes within a time frame, adhering to a specific 
             loading order and returning to the bay for additional loads. This design showcases precision and efficient 
             integration of software and hardware, operating within material and budget constraints."

            ghLink="https://github.com/Chrishatoum/Cube-Dropping-Robot"
           
          />
        </Col>
      </Row>
    </Container>
 
  </Container>
 
  <div className="footer">
        <h2 className='building-text'>Creating something cool? <br></br>I'd love to chat.</h2>
        <Link to="/contact" className="bottom-contact">
      Contact <br></br>
      </Link>
    <div className='copy'> 
      <span className='copyright'>Copyright © </span>
      <a href="./" class="line-link text-white font-weight-light" target="_blank" rel="nofollow">Chris Hatoum</a>
    </div>
  </div>
</div>
   
    
  );
}

export default Projects;