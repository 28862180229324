import React, { useEffect, useRef } from 'react';
import { VANTA } from 'vanta';
import { GLOBE } from 'vanta/dist/vanta.globe.min';


const VantaGlobe = () => {
  const vantaRef = useRef(null);
  let vantaEffect = useRef(null);

  useEffect(() => {
    const startVanta = () => {
      if (!vantaEffect.current) {
        vantaEffect.current = VANTA.GLOBE({
          el: vantaRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1,
          scaleMobile: 1.0,
          size: 0.7, // Adjust the size to cover the whole page
          color: 0xffd700,
          backgroundColor: 0x121E27
        });
      }
    };

    const stopVanta = () => {
      if (vantaEffect.current) {
        vantaEffect.current.destroy();
      }
    };

    startVanta();

    return () => {
      stopVanta();
    };
  }, []);

  return <div ref={vantaRef} style={{ width: '100%', height: '100vh' }} />;
};

export default VantaGlobe;