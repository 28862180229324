import React, { useState, useEffect } from 'react';
import './index.scss'; // Import your SCSS stylesheet
import Charlie from '../../assets/images/Charlie Cycling.jpeg';
import Toto from '../../assets/images/Toto Marathon.jpeg';
import { Link } from 'react-router-dom'
import Loader from 'react-loaders'
import Typed from 'react-typed';
import resume from '../../assets/pdf/Resume-Chris Hatoum.pdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';





const About = () => {
  const typedStrings = ["Welcome to My Website!","I'm Chris !"];
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [imageIndex, setImageIndex] = useState(0);
  const [typedText, setTypedText] = useState("");
  const images = [Charlie, Toto];
  const skills = [
    "Java", "Python", "C", "Bash", "VHDL", "Assembly", "React", "HTML", 
    "CSS", "JavaScript", "MySQL", "GitHub", "EDA Playground", "Visual Studio",
    "Git", "Umple", "Eclipse", "Figma","MS Office", "Vue.js", "Gradle","Postgres","UML",
    "JavaFX", "Gherkin", "Cucumber", "PyTorch"
    
  ]; 
  const Languages = [
    "Arabic", "English", "French"
    
  ]; 

 
  

  const experience = [
    {
      title: 'Software Engineer Intern',
      company: 'Wager API',
      date: '06/2023 - 01/2024',
      location: 'Remote - Florida, United States',
      description: [
        'Fullstack software engineer building web scrapers'
      ].join(' ')
    },
    {
      title: 'Software Engineer Intern',
      company: 'Rogers',
      date: '08/2022 - 12/2022',
      location: 'Montreal, Canada',
      description: [
        'Designed, developed, and deployed scripts to automate certain features in TAF (Technology Automation Framework)',
        'Managed and created alarms, automations, IMT’s, NCT’s with technologies such as ESAP, REMEDY, TAF…'
      ].join(' ')
    },
    {
      title: 'Software Developer Intern',
      company: 'Born Interactive',
      date: '05/2020 - 08/2020',
      location: 'Beirut, Lebanon',
      description: [
        'Participated in software development projects, gaining hands-on experience in coding, and debugging.',
        'Assisted in the design and development of software applications, improving problem solving and coding skills.'
      ].join(' ')
    },
    {
      title: 'Lead Software Engineer',
      company: 'McGill Formula Electric',
      date: '08/2020 - 08/2022',
      location: 'Montreal, QC',
      description: [
        'Utilized CubeMX to generate code on our periphals and pins of PCB’s '
      ].join(' ')
    },
    {
      title: 'Technical Research Analyst ',
      company: 'Ogero',
      date: '01/2019 - 04/2019',
      location: 'Beirut, Lebanon',
      description: [
        ' Experienced in leading technical interviews in French and Arabic, proficient in engaging with diverse clients to gather insights on various technologies and their applications.'
      ].join(' ')
    }
    
    ,{
      title: 'Restauration work',
      date: '08/2020 - Present',
      company: 'Omnivore / Il Cortile',
      location: 'Montreal, Canada',
      description: [
        'Acquired valuable interpersonal, customer service, and restoration skills through experience in the restaurant industry.',
        'Manager at Omnivore (Oriental Restaurant)'
      ].join(' ')
    }
  ];

  const education = [
    {
      institution: 'McGill University',
      degree: 'Bachelor of Engineering, Computer Engineering',
      date: '2020-2024',
      location: 'Montreal, QC',
      details: 'CGPA: 3.92 / 4.0.   Dean’s Honor List, James McGill scholarship, Faculty of Engineering scholarship, Rio Tinto-Evans Exchange scholarship'
    },
    {
      institution: 'Collège Sainte Famille Fanar',
      degree: 'French Baccalaureate (Scientific orientation: Mathematics Specialty)',
      date: '2004-2019',
      location: ' Beirut, LEB',
      details: 'Jury’s congratulations (17.73/20)'
    }
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    const interval = setInterval(() => {
      setImageIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
    }, 5000); // Switch images every 5 seconds

    return () => clearInterval(interval); // Clear the interval on component unmount
  }, []);

  useEffect(() => {
    let scrollTimeout;

    const handleScroll = () => {
      // Show scrollbar when user starts scrolling
      document.body.style.setProperty('--scrollbar-width', '8px');

      // Clear the timeout if it's already set
      clearTimeout(scrollTimeout);

      // Set a timeout to hide scrollbar after 1.5s of inactivity
      scrollTimeout = setTimeout(() => {
        document.body.style.setProperty('--scrollbar-width', '0');
      }, 1500);
    };

    // Add the scroll event listener to the body
    document.body.addEventListener('scroll', handleScroll);

    // Cleanup function: remove the event listener when the component is unmounted
    return () => {
      document.body.removeEventListener('scroll', handleScroll);
    };

  }, []);
  useEffect(() => {
    const checkScrollTop = () => {
      if (!showScrollTop && window.pageYOffset > 400) {
        setShowScrollTop(true);
      } else if (showScrollTop && window.pageYOffset <= 400) {
        setShowScrollTop(false);
      }
    };

    window.addEventListener('scroll', checkScrollTop);

    return () => window.removeEventListener('scroll', checkScrollTop);
  }, [showScrollTop]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


 


  return (
    
    <div className="about">
      <Loader type="pacman" />
      {showScrollTop && (
        <button onClick={scrollTop}  className='arrow'>↑ </button>
      )}
      <div className="container">
        <div className="about-content">
          <div className="about-images">
            <img src={images[imageIndex]} alt="Charlie or Toto" />
          </div>
          <div className="about-details">
            <h1><Typed
            strings={typedStrings}
            typeSpeed={60}
            backSpeed={45}
            loop={false}
          /></h1>
          <p className='code-runner'>A Code Runner, when software meets sports. </p>
            <p className ="description-text">

              <br></br> I'm a passionate 4th-year Computer Engineering student by day and a restaurateur by night. My life is a bit like coding: complex, challenging, but oh so rewarding.
              <br></br>
               <br></br>When I'm not knee-deep in code or wrapping a perfect shawarma, I'm lacing up my sneakers for the next Ironman challenge which can be as tricky as teaching my dog 🐾Charlie to 🚴‍♂️cycle or convincing my friend Toto that 🏃‍♂️running marathons is just an elaborate form of group jogging.It's not all about the grind, though; I’m also on the perpetual hunt for my next quirky hobby and a good laugh to round out the day.
               <br></br>  I fund my studies and sustain myself by serving up smiles and the daily special in the evenings. It's this unique blend of tech, grit, and hospitality that fuels my drive.
               <br></br>
               <br></br>Got a coding conundrum or just looking to swap stories over a virtual coffee? I’m your guy. Let’s join forces and cook up something cool!

            </p>
            <br></br>
            <br></br>
            <a className='download' href={resume} target="_blank" >
   Download my resume <FontAwesomeIcon icon={faDownload} />
</a>

          </div>
        </div>
        <div className="skills-section">
          <h2>Languages</h2>
          <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
            {Languages.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
        <div className="skills-section">
          <h2>Skills</h2>
          <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
            {skills.map((skill, index) => (
              <li key={index}>{skill}</li>
            ))}
          </ul>
        </div>
        <div className="experience-section">
          <h2>Experience</h2>
          {experience.map((item, index) => (
            <div key={index} className="experience-item">
              <h3>{item.title}</h3>
              <p>{item.company} - {item.date}</p>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
        <div className="education-section">
          <h2>Education</h2>
          {education.map((item, index) => (
            <div key={index} className="education-item">
              <h3>{item.institution}  -  {item.location}</h3>
              <p>{item.degree} - {item.date}</p>
              <p>{item.details}</p>
            </div>
          ))}
        </div>
        <div className="footer">
          <h2 className='building-text'>Creating something cool? <br></br>I'd love to chat.</h2>
          <Link to="/contact" className="bottom-contact">
        Contact <br></br>
        </Link>
        <div className='copy'> 
        <span className='copyright'>Copyright © </span>
        <a href="./" class="line-link text-white font-weight-light" target="_blank" rel="nofollow">Chris Hatoum</a>
        </div>
        </div>
        
        
        
        </div>
    <Link to="/contact" className="flat-button-top">
        Contact Me
    </Link>
    
  </div>
  );
};

export default About;