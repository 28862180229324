import './index.scss'

const AnimatedLetters = ({ letterClass, strArray, idx }) => {
  // Handle mouse enter
  const handleMouseEnter = (e) => {
    e.target.classList.add('rubberBand-animation'); // Add the animation class
  };

  // Handle mouse leave
  const handleAnimationEnd = (e) => {
    e.target.classList.remove('rubberBand-animation'); // Remove the animation class after it ends
  };

  return (
    <span>
      {strArray.map((char, i) => (
        <span
          key={char + i}
          className={`${letterClass} _${i + idx}`}
          onMouseEnter={handleMouseEnter}
          onAnimationEnd={handleAnimationEnd} // Add this to ensure the class is removed after the animation
        >
          {char}
        </span>
      ))}
    </span>
  );
};

export default AnimatedLetters;

